<template>
  <div>
    <KTCodePreview v-bind:title="'Đổi sản phẩm'">
      <template v-slot:preview>
        <div class="col-12 row" style="padding-left: 0px">
          <b-form-group label-for="input-1" class="col-3">
            <template>
              <span>Kho:</span>
              <span class="text-danger">*</span>
            </template>
            <vue-autosuggest
              class="border-radius-none mt-2"
              :suggestions="filteredOptions"
              @selected="onSelectedStore"
              :limit="200"
              @input="onInputChange"
              :input-props="inputPropStore"
              :should-render-suggestions="
                (size, loading) => size >= 0 && !loading
              "
              v-model="searchStore"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div style="{ display: 'flex', color: 'navyblue'}">
                  {{ suggestion.item.suggestionName }}
                </div>
              </div>
            </vue-autosuggest>
          </b-form-group>
        </div>
        <b-row>
          <b-col lg="6" md="6" sm="6" style="padding-right: 30px">
            <b-form-group>
              <template>
                <span>Ghi chú:</span>
              </template>
              <b-form-textarea
                size="sm"
                v-model="notes"
                :placeholder="'Thêm nội dung ghi chú...'"
                :rows="3"
                :max-rows="3"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <span class="font-weight-bolder">Tìm sản phẩm:</span>
        <b-row class="mb-4 mt-4 col-md-12" style="padding-left: 0px">
          <b-col class="col-md-6">
            <vue-autosuggest
              v-model="searchProduct"
              :suggestions="filteredOptionsProduct"
              @selected="onSelectedProduct"
              :limit="10"
              @input="onInputChangProduct"
              :input-props="inputSearchProductProp"
              :should-render-suggestions="
                (size, loading) => size >= 0 && !loading && searchProduct !== ''
              "
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div style="{ display: 'flex', color: 'navyblue'}">
                  {{ suggestion.item.productName }}
                </div>
              </div>
            </vue-autosuggest>
          </b-col>
        </b-row>
        <span class="font-weight-bolder mb-4">Danh sách sản phẩm:</span>
        <b-table
          class="table-bordered table-hover col-md-12"
          :fields="fields"
          :items="listProductStock"
          v-if="renderComponent"
        >
          <template v-slot:cell(productNameNew)="row">
            <div @change="onChangeProduct(row.item)">
              <vue-autosuggest
                v-model="row.item.productNameNew"
                :suggestions="filteredOptionsProductNew"
                @selected="onSelectedProductNew"
                :limit="10"
                @input="onInputChangeProductNew"
                :get-suggestion-value="getSuggestionValue"
                :input-props="{
                  id: 'autosuggest__input',
                  placeholder: 'Nhập sản phẩm mới',
                  style: 'border-radius:0px!important',
                }"
              >
                <template slot-scope="{ suggestion }">
                  <div>{{ suggestion.item.productName }}</div>
                </template>
              </vue-autosuggest>
            </div>
          </template>
          <template v-slot:cell(imeis)="row">
            <b-form-textarea
              size="sm"
              v-model="row.item.imeis"
              :placeholder="'Mã IMEI'"
              :rows="6"
              :max-rows="6"
              @keyup="enterClicked(row.item)"
            ></b-form-textarea>
          </template>
          <template v-slot:cell(price)="row">
            <b-form-input
              size="sm"
              v-model="row.item.price"
              placeholder="Nhập giá"
              class="text-right"
              v-mask="mask"
            ></b-form-input>
          </template>
          <template v-slot:cell(actions)="row">
            <v-icon
              small
              class="text-danger text-center"
              @click="deleteItemInArray(row.item.id)"
              v-b-tooltip
              title="Xóa"
              >mdi-delete</v-icon
            >
          </template>
        </b-table>

        <div class="mb-5 mt-5">
          <button
            :disabled="listProductStock.length === 0"
            v-bind:style="btnCreate"
            type="button"
            class="btn btn-primary font-weight-bolder btn-sm button"
            @click="createStock"
          >
            Lưu
          </button>
          <router-link to="/imeis" tag="button">
            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              >Hủy</b-button
            >
          </router-link>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style>
.title-center {
  text-align: center;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.input-group-append {
  cursor: pointer;
  padding-top: 5.5px;
}

.input-group-append:hover i {
  color: #3699ff;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import decounce from 'debounce';
import { TIME_TRIGGER } from './../../../utils/constants';
import { v4 } from 'uuid';
import { currencyMask, unMaskPrice } from './../../../utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
        width: '70px',
      },
      form: {
        providerSelected: null,
      },
      isNew: true,
      errors: [],
      listInventories: [],
      totalPrice: 0,
      listProduct: [],
      listProductSearch: [],
      count: 0,
      listType: [
        { id: 1, name: 'Xuất trả hàng nhà cung cấp' },
        { id: 2, name: 'Xuất kho bán hàng' },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      fields: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'imeis',
          label: 'IMEI',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-left',
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
        },
        {
          key: 'productNameNew',
          label: 'Sản phẩm mới',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-left',
        },
        {
          key: 'price',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      inventorySelected: null,
      inputSearchProductProp: {
        id: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      totalQuantity: 0,
      filteredOptionsProductNew: [],
      optionsProductNew: [
        {
          data: [],
        },
      ],
      searchProductNew: '',
      isSearching: false,
      inputPropStore: {
        id: 'autosuggest__input',
        placeholder: 'Chọn kho',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      renderComponent: true,
      notes: '',
      currentIndex: null,
      mask: currencyMask,
    };
  },
  validations: {},
  components: {
    KTCodePreview,
  },
  created() {
    this.fetchStore();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách IMEI', route: '/imeis' },
      { title: 'Đổi sản phẩm' },
    ]);
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      let quantity = 0;
      this.searchProduct = option.item.productName;
      const pro = {
        id: this.newId(),
        productId: this.selectedClassProduct.productId,
        productName: this.selectedClassProduct.productName,
        productCode: this.selectedClassProduct.productCode,
        imeis: '',
        quantity: quantity,
        price: 0,
        productIdNew: null,
        productNameNew: '',
      };
      this.listProductStock.push(pro);
      this.searchProduct = '';
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    createStock: async function () {
      this.isNew = false;

      const listPro = this.listProductStock.map((element) => {
        return {
          productId: element.productId,
          quantity: element.quantity,
          imeis: element.imeis.replace(/[\n\r]/g, ', '),
          productIdNew: element.productIdNew,
          price: element.price ? unMaskPrice(element.price) : 0,
        };
      });
      const data = {
        storeId: this.inventorySelected,
        listProduct: listPro,
        description: this.notes,
      };
      if (listPro.length > 0) {
        ApiService.post('imeis/change-product-imei', data)
          .then(({ data }) => {
            if (data.status === 1) {
              this.makeToastSuccess(data.message);
              this.$router.push({
                name: 'list-imeis',
              });
            } else {
              this.makeToastFaile(data.message);
              this.isNew = true;
            }
          })
          .catch(({ response }) => {
            this.makeToastFaile(response.$error);
          });
      } else {
        this.makeToastFaile('Vui lòng chọn sản phẩm muốn cân kho!');
        this.isNew = true;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchStore() {
      ApiService.setHeader();
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
        this.listInventories.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      ApiService.setHeader();
      ApiService.get(
        `productSearch/for-change-product?storeId=${this.inventorySelected}&searchProduct=${textSearch}`
      )
        .then(({ data }) => {
          this.optionsProduct[0].data = [];
          const products = data.data;
          products.map((element) => {
            this.optionsProduct[0].data.push(element);
          });
          this.filteredOptionsProduct = [
            {
              data: this.optionsProduct[0].data,
            },
          ];
          this.isSearching = false;
          if (textSearch !== this.searchProduct) {
            let nextSearchProduct = this.searchProduct;
            this.fetchProduct(nextSearchProduct);
          }
        })
        .catch(() => (this.isSearching = false));
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    onChangeStore() {
      this.listProductStock = [];
    },
    fetchProductNew(textSearch) {
      this.isSearching = true;
      ApiService.setHeader();
      ApiService.get(
        `productSearch/for-change-product?storeId=${this.inventorySelected}&searchProduct=${textSearch}`
      )
        .then(({ data }) => {
          this.optionsProductNew[0].data = [];
          const products = data.data;
          products.map((element) => {
            this.optionsProductNew[0].data.push(element);
          });
          this.filteredOptionsProductNew = [
            {
              data: this.optionsProductNew[0].data,
            },
          ];
          this.isSearching = false;
          if (textSearch !== this.searchProductNew) {
            let nextSearchProduct = this.searchProductNew;
            this.fetchProductNew(nextSearchProduct);
          }
        })
        .catch(() => (this.isSearching = false));
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.inventorySelected = option.item.id;
      this.inputSearchProductProp.disabled = false;
      this.onChangeStore();
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      //this.onChangeStore();
    },
    searchProductAPI() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    },
    searchProductAPINew() {
      if (!this.isSearching) {
        let textSearch = this.searchProductNew;
        this.fetchProductNew(textSearch);
      }
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    newId() {
      return v4();
    },
    onChangeProduct(selectedItem) {
      this.currentIndex = this.listProductStock.findIndex(
        (item) => item.id == selectedItem.id
      );
    },
    onInputChangeProductNew(text) {
      if (!text) {
        text = '';
      }
      this.searchProductNew = text;
      this.debounceInputProductNew();
    },
    debounceInputProductNew: decounce(function () {
      this.searchProductAPINew();
    }, TIME_TRIGGER),
    onSelectedProductNew(option) {
      this.searchProductNew = option.item.productName;
      this.listProductStock[this.currentIndex].productIdNew =
        option.item.productId;
      this.listProductStock[this.currentIndex].productNameNew =
        option.item.productName;
    },
    enterClicked(selectedItem) {
      this.currentIndex = this.listProductStock.findIndex(
        (item) => item.id == selectedItem.id
      );
      let countLine = 0;
      let tmp =
        this.listProductStock[this.currentIndex].imeis.split(/\r\n|\r|\n/);
      tmp.forEach((element) => {
        if (element.trim() !== '') {
          countLine++;
        }
      });
      this.listProductStock[this.currentIndex].quantity = countLine;
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.productName;
    },
  },
};
</script>
