var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('KTCodePreview',{attrs:{"title":'Đổi sản phẩm'},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('div',{staticClass:"col-12 row",staticStyle:{"padding-left":"0px"}},[_c('b-form-group',{staticClass:"col-3",attrs:{"label-for":"input-1"}},[[_c('span',[_vm._v("Kho:")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])],_c('vue-autosuggest',{staticClass:"border-radius-none mt-2",attrs:{"suggestions":_vm.filteredOptions,"limit":200,"input-props":_vm.inputPropStore,"should-render-suggestions":function (size, loading) { return size >= 0 && !loading; }},on:{"selected":_vm.onSelectedStore,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(" "+_vm._s(suggestion.item.suggestionName)+" ")])])}}]),model:{value:(_vm.searchStore),callback:function ($$v) {_vm.searchStore=$$v},expression:"searchStore"}})],2)],1),_c('b-row',[_c('b-col',{staticStyle:{"padding-right":"30px"},attrs:{"lg":"6","md":"6","sm":"6"}},[_c('b-form-group',[[_c('span',[_vm._v("Ghi chú:")])],_c('b-form-textarea',{attrs:{"size":"sm","placeholder":'Thêm nội dung ghi chú...',"rows":3,"max-rows":3},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],2)],1)],1),_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Tìm sản phẩm:")]),_c('b-row',{staticClass:"mb-4 mt-4 col-md-12",staticStyle:{"padding-left":"0px"}},[_c('b-col',{staticClass:"col-md-6"},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsProduct,"limit":10,"input-props":_vm.inputSearchProductProp,"should-render-suggestions":function (size, loading) { return size >= 0 && !loading && _vm.searchProduct !== ''; }},on:{"selected":_vm.onSelectedProduct,"input":_vm.onInputChangProduct},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"{ display":"'flex', color: 'navyblue'}"}},[_vm._v(" "+_vm._s(suggestion.item.productName)+" ")])])}}]),model:{value:(_vm.searchProduct),callback:function ($$v) {_vm.searchProduct=$$v},expression:"searchProduct"}})],1)],1),_c('span',{staticClass:"font-weight-bolder mb-4"},[_vm._v("Danh sách sản phẩm:")]),(_vm.renderComponent)?_c('b-table',{staticClass:"table-bordered table-hover col-md-12",attrs:{"fields":_vm.fields,"items":_vm.listProductStock},scopedSlots:_vm._u([{key:"cell(productNameNew)",fn:function(row){return [_c('div',{on:{"change":function($event){return _vm.onChangeProduct(row.item)}}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsProductNew,"limit":10,"get-suggestion-value":_vm.getSuggestionValue,"input-props":{
                id: 'autosuggest__input',
                placeholder: 'Nhập sản phẩm mới',
                style: 'border-radius:0px!important',
              }},on:{"selected":_vm.onSelectedProductNew,"input":_vm.onInputChangeProductNew},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var suggestion = ref.suggestion;
return [_c('div',[_vm._v(_vm._s(suggestion.item.productName))])]}}],null,true),model:{value:(row.item.productNameNew),callback:function ($$v) {_vm.$set(row.item, "productNameNew", $$v)},expression:"row.item.productNameNew"}})],1)]}},{key:"cell(imeis)",fn:function(row){return [_c('b-form-textarea',{attrs:{"size":"sm","placeholder":'Mã IMEI',"rows":6,"max-rows":6},on:{"keyup":function($event){return _vm.enterClicked(row.item)}},model:{value:(row.item.imeis),callback:function ($$v) {_vm.$set(row.item, "imeis", $$v)},expression:"row.item.imeis"}})]}},{key:"cell(price)",fn:function(row){return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],staticClass:"text-right",attrs:{"size":"sm","placeholder":"Nhập giá"},model:{value:(row.item.price),callback:function ($$v) {_vm.$set(row.item, "price", $$v)},expression:"row.item.price"}})]}},{key:"cell(actions)",fn:function(row){return [_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"text-danger text-center",attrs:{"small":"","title":"Xóa"},on:{"click":function($event){return _vm.deleteItemInArray(row.item.id)}}},[_vm._v("mdi-delete")])]}}],null,false,3611469994)}):_vm._e(),_c('div',{staticClass:"mb-5 mt-5"},[_c('button',{staticClass:"btn btn-primary font-weight-bolder btn-sm button",style:(_vm.btnCreate),attrs:{"disabled":_vm.listProductStock.length === 0,"type":"button"},on:{"click":_vm.createStock}},[_vm._v(" Lưu ")]),_c('router-link',{attrs:{"to":"/imeis","tag":"button"}},[_c('b-button',{staticStyle:{"margin-left":"10px","font-weight":"600","width":"70px"},attrs:{"variant":"secondary","size":"sm"}},[_vm._v("Hủy")])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }